// import axios from 'axios';
import AuthenticationService from "../../services/authentication.service";
// const API_URL = process.env.VUE_APP_API_URL;
const getDefaultState = () => {
  return {
    originList: [],
    waitList: [],
  }
}

const state = getDefaultState()

const getters = {}

const actions = {
  CHECK_CASINO_WALLET(_, params) {
    return AuthenticationService.post(`/casino/cx/wallet`, params).then(
      (response) => {
        return response;
      }
    );
  },
  GET_LIVE(_, params) {
    return AuthenticationService.post(`/casino/cx/lobby/live`, params).then(
      (response) => {
        return response;
      }
    );
  },
  GET_SLOT(_, params) {
    return AuthenticationService.post(`/casino/cx/lobby/slot`, params).then(
      (response) => {
        return response;
      }
    );
  },
  GET_GAME(_, params) {
    return AuthenticationService.post(
      `/casino/cx/game`, params
    ).then((response) => {
      return response;
    });
  },
};

const mutations = {
  SET_INPLAY_LIST(state, payload) {
    state.originList = payload
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
